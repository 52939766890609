import { Container, Typography } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'
import { SERMON_PAGE_TITLE } from './strings'
import 'react-h5-audio-player/lib/styles.css'
import SectionBanner from '../common/SectionBanner'
import SermonList from './SermonList'
import customTheme from '../theme'

import sermons_data from './data.json'
import RecentSermonList from './RecentSermonList'

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  ${customTheme.breakpoints.down('md')} {
    padding: 0 0.5%;
  }

  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`

const ListContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
`

const SermonSection: FC = () => {
  return (
    <SectionContainer>
      <ListContainer>
        <Typography variant="h3" sx={{ marginBottom: '16px' }}>
          Recent Sermons
        </Typography>
        <RecentSermonList recentSermons={sermons_data.sermons.slice(0, 3)} />
      </ListContainer>
      <Typography variant="h3" sx={{ marginBottom: '16px' }}>
        All Sermons
      </Typography>
      <SermonList sermons={sermons_data.sermons} />
    </SectionContainer>
  )
}

const SermonView: FC = () => {
  return (
    <>
      <SectionBanner title={SERMON_PAGE_TITLE} />
      <Container maxWidth="md" sx={{ mt: '4%' }}>
        <SermonSection />
      </Container>
    </>
  )
}

export default SermonView
