import {
  Button,
  CircularProgress,
  Link,
  Tooltip,
  Typography,
} from '@mui/material'
import styled from 'styled-components'
import {
  DataGrid,
  getGridStringOperators,
  GridColDef,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import YouTubeIcon from '@mui/icons-material/YouTube'
import PodcastsIcon from '@mui/icons-material/Podcasts'
import PlayArrow from '@mui/icons-material/PlayArrow'
import { format, parseISO } from 'date-fns'
import { FC, useState, useEffect } from 'react'
import { Sermon } from '../client/types/sermon'
import 'react-h5-audio-player/lib/styles.css'
import { NO_SERMONS_FOUND } from './strings'
import sermon_series_data from './sermon-series.json'

const DataGridContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 90vw;
`

type SermonListProps = {
  /**
   * The sermon series that is populating the sermon list.
   */
  sermons: Sermon[]
}

const columns: GridColDef<any>[] = [
  {
    field: 'title',
    headerName: 'Title',
    minWidth: 200,
    disableColumnMenu: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === 'equals' || operator.value === 'contains'
    ),
  },
  {
    field: 'verse',
    headerName: 'Verse',
    minWidth: 200,
    disableColumnMenu: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === 'contains'
    ),
  },
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 200,
    type: 'date',
    disableColumnMenu: true,
    valueGetter: (value) => parseISO(value),
    valueFormatter: (value) => format(value, 'PPP'),
  },
  {
    field: 'tagId',
    headerName: 'Series',
    minWidth: 200,
    sortable: true,
    type: 'singleSelect',
    disableColumnMenu: true,
    getOptionValue: (value: any) => (value != null ? value.tagId : 'NULL'),
    getOptionLabel: (value: any) => (value != null ? value.title : 'NULL'),
    valueOptions: sermon_series_data.sermonSeriesList,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === 'equals' || operator.value === 'contains'
    ),
  },
  {
    field: 'speakerName',
    headerName: 'Speaker Name',
    minWidth: 200,
    sortable: true,
    disableColumnMenu: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === 'equals' || operator.value === 'contains'
    ),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    filterable: false,
    minWidth: 200,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <>
          {params.row.videoLocation && (
            <Tooltip title="Watch on YouTube" arrow>
              <Button component={Link} href={params.row.videoLocation}>
                <YouTubeIcon />
              </Button>
            </Tooltip>
          )}
          {params.row.audioLocation && (
            <Tooltip title="Currently unavailable" arrow>
              <Button>
                <PlayArrow />
              </Button>
            </Tooltip>
          )}
          {params.row.spotifyLocation && (
            <Tooltip title="Listen on Spotify" arrow>
              <Button component={Link} href={params.row.spotifyLocation}>
                <PodcastsIcon />
              </Button>
            </Tooltip>
          )}
        </>
      )
    },
  },
]

const SermonListGridToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: 'Change density' } }}
      />
    </GridToolbarContainer>
  )
}

const SermonList: FC<SermonListProps> = ({ sermons }) => {
  // States to track sermon list and the API call associated with the sermon list.
  const [sermonList, setSermonList] = useState<Sermon[]>(sermons)
  const [isSermonsLoading, setIsSermonsLoading] = useState<boolean>(false)

  // Only load new sermons if the sermon series has changed.
  useEffect(() => {
    const getSermonList = () => {
      setSermonList(sermons)
      setIsSermonsLoading(false)
    }

    setIsSermonsLoading(true)
    getSermonList()
  }, [])

  if (isSermonsLoading) {
    return <CircularProgress color="primary" />
  }

  if (sermonList.length === 0) {
    return <Typography variant="h2">{NO_SERMONS_FOUND}</Typography>
  }

  return (
    <DataGridContainer>
      <DataGrid
        rows={sermonList}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        onStateChange={() =>
          window.dispatchEvent(new Event('esv-crossref.trigger-linkify'))
        }
        slots={{ toolbar: SermonListGridToolbar }}
        pageSizeOptions={[10, 25, 100]}
      />
    </DataGridContainer>
  )
}

export default SermonList
