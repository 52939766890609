import { InfoCardContentProps } from '../common/InfoCard'

/**
 * Ministry partner section
 */

export const MINISTRY_PARTNER_LIST: InfoCardContentProps[] = [
  {
    title: 'Living Well',
    description: `We believe that every life is precious and made in the image of God, starting from the womb. This is why we've partnered with LivingWell Pregnancy Center. For almost 35 years, LivingWell has been providing practical solutions and medical care for women in Orange County facing unplanned pregnancies. They've helped over 125,000 women with free pregnancy testing, ultrasounds, counseling, and educational classes. We pray that together we'd be able to help LivingWell provide compassionate and practical care for women facing unplanned pregnancies.`,
    link: 'https://www.living-well.org/',
  },
  {
    title: 'Los Angeles Bible Training School (LABTS)',
    description: `All scripture is breathed by God and profitable for teaching, and that's why we've partnered with Los Angeles Bible Training School. Since 1962, the school has been dedicated to providing systematic Bible training to believers so that they can serve their local bodies. Any believer is welcome to enroll and there's no tuition. Our partnership helps support the staff to be able to equip local believers to not only strengthen their knowledge and faith, but also take this to do the work of God in their communities.`,
    link: 'https://lighthousesouthbay.org/resources/stories/get-to-know-the-los-angeles-bible-training-school/',
  },
  {
    title: 'Olive Crest',
    description:
      "With over 400,000 children in the welfare system in the U.S, many will struggle with depression, substance abuse, or PTSD as a result. We believe that the church can come alongside these children to break out of this cycle. That is why we've partnered with Olive Crest in their goal to prevent child abuse and unify families by providing safe homes and becoming foster families. We pray that as a church, we can love and care for these children as we have been loved and adopted by our Heavenly Father.",
    link: 'https://www.olivecrest.org/',
  },
  {
    title: 'Orange County Rescue Mission',
    description: `We desire to serve and love the vulnerable in our community as Christ has loved us. Thats why we've partnered with Orange County Rescue Mission in their efforts to equip and support homeless men, women, and children. Their Village of Hope is the most comprehensive living facility for the homeless in the country. Residents have access to job training, life skill classes, medical care, after-school tutoring, and spiritual counseling.`,
    link: 'https://www.rescuemission.org/',
  },
  {
    title: 'Pacific Justice Institute',
    description:
      'Given the cultural and political environment we live in, we know that the Church is subject to lawsuits and discrimination. Pacific Justice Institute is a non-profit legal defense organization that provides clients with legal support on religious freedom, parental rights, and other civil liberties, without charge. By supporting their network of volunteer attorneys worldwide, we hope to be able to help churches, leaders, and believers live out their faith, without fear of legal persecution.',
    link: 'https://pacificjustice.org/',
  },
]

/**
 * Children's ministry section
 */

export const CHILDREN_MINISTRY_SECTION_DESCRIPTION_1 = `The aim of the Children's Ministry at CrossLife Bible Church is to teach the whole counsel of God to our children, in order that they might know our heavenly Father and that they would place their faith and hope in the gospel of Jesus Christ. We desire to partner with families as they disciple the next generation to know and love their Creator and Savior.`

/**
 * Community group section
 */

export const COMMUNITY_GROUP_DESCRIPTION =
  'Community Groups meet on Friday nights and are a great way to connect with the CrossLife community. Community Groups are open to all and vary in location, topics, and cadence. Please contact planning@crosslifebiblechurch.org for more details on the community groups.'

/**
 * College
 */

export const CCF_TITLE = 'CrossLife College Fellowship'

export const CCF_TIME_AND_LOCATION_TITLE = 'Time and Location'
export const CCF_TIME_AND_LOCATION_DETAIL =
  'Every Thursday at 7:30PM @ UCI, Room: PCB 1100'

export const CCF_TOPICS_TITLE = 'Topics'
export const CCF_TOPICS_LIST = [
  '🍂 Fall:  Dating Series',
  '❄️ Winter: Biblical Reconciliation',
  '🍃 Spring: A Study in Soteriology (Doctrine of Salvation)',
]

export const CCF_ABOUT_TITLE = 'About CrossLife College Fellowship (CCF)'
export const CCF_ABOUT_DESCRIPTION =
  "CrossLife College Fellowship (CCF) is our on-campus ministry. Our vision is to reach UCI for the Lord Jesus Christ. Every Thursday night at 7:30PM, we get together for a time of praise, prayer, and the preaching of God's word. Each quarter, Pastor James goes through a topical sermon series covering topics such as spiritual disciplines, relationships, the theology of work, and church history. If you're looking for a Christian community and you're wanting to learn more about the word of God, we would love to have you come and join us for fellowship. We also welcome anyone who might be interested and have questions about the Christian faith. We hope to be a resource to help you understand the Bible and the message of the Gospel of Jesus Christ."

export const CCF_ABOUT_GOSPEL_NIGHT_TITLE = 'About Gospel Night'
export const CCF_ABOUT_GOSPEL_NIGHT_DESCRIPTION =
  "On Thursday, January 12, 2023 at 8PM, CrossLife Bible Church and Berean Community Church will be hosting a joint Gospel Night on UCI's campus, where we will be teaching about Christianity. Our guest speaker, Pastor Chris Gee from City Light Bible Church, will be explaining the significance of Jesus' death upon the cross and His death's power to forgive us of our sins and wrongdoing so that we can enter into heaven through faith. If you have any questions about meaning, purpose, morality, God, Jesus, and a life beyond the grave, this is a great opportunity to come and learn. We will have a number of pastors after the event who will be available to answer any questions you may have. We hope you can join us for the event and we look forward to meeting you!"

export const CCF_SMALL_GROUPS_TITLE = 'Small Groups'
export const CCF_SMALL_GROUPS_DESCRIPTION =
  'The best way to get more info and to contact these small group leaders is to join our Discord channel. Alternatively, please email '

/**
 * College small group section
 */

export const COLLEGE_WOMEN_SMALL_GROUP_TITLE = "Women's Small Group"

export const COLLEGE_MEN_SMALL_GROUP_TITLE = "Men's Small Group"

/**
 * Welcome Week section
 */
export type WelcomeWeekEvent = {
  title: string
  time: string
  location: string
  details?: string | undefined
}

export const CCF_WELCOME_WEEK_TITLE = 'Welcome Week Events'
export const CCF_WELCOME_WEEK_EVENTS: WelcomeWeekEvent[] = [
  {
    title: 'Boba Night at Berkeley Court Clubhouse',
    time: 'Tuesday, September 24, 6:00 pm - 10:00 pm',
    location: 'Berkeley Clubhouse, 307 Berkeley Ave, Irvine, CA 92612',
    details:
      'Meet at UCI flagpoles at 6:00 pm.  We will walk over together to the clubhouse.',
  },
  {
    title: 'Bonfire Night at the Beach',
    time: 'Thursday, September 26, 4:30 pm - 10:00 pm',
    location: 'Beach is likely Corona Del Mar',
    details: 'Rides provided at flagpoles 4:30 pm and 6:30 pm',
  },
  {
    title: 'Korean Food and Game Night at Church',
    time: 'Saturday, September 28, 4:00 pm - 10:00 pm',
    location: 'CrossLife Bible Church, 1 Tarocco, Irvine, CA 92618',
    details: 'Rides provided at flagpoles at 4:00 pm',
  },
]
