import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import App from './App'
import Home from './home'
import Lesson from './lesson'
import ChurchMembership from './lesson/ChurchMembership'
import EquippingClasses from './lesson/EquippingClasses'
import Ministry from './ministry'
import AdultSmallGroups from './ministry/AdultSmallGroups'
import ChildrensMinistry from './ministry/ChildrensMinistry'
import CommunityGroups from './ministry/CommunityGroups'
import MinistryPartners from './ministry/MinistryPartners'
import MensMinistry from './ministry/MensMinistry'
import WomensMinistry from './ministry/WomensMinistry'
import GivingView from './giving'
import NewHere from './new-here'
import ConstitutionSection from './new-here/ConstitutionSection'
import DoctrinalStatementSection from './new-here/DoctrinalStatementSection'
import FaqsSection from './new-here/FaqsSection'
import GospelSection from './new-here/GospelSection'
import LeadershipSection from './new-here/leadership/LeadershipSection'
import MembershipCovenantSection from './new-here/MembershipCovenantSection'
import RidesSection from './new-here/RidesSection'
import TimesAndLocationSection from './new-here/TimeAndLocationSection'
import WelcomeSection from './new-here/WelcomeSection'
import SermonView from './sermon'
import customTheme from './theme'
import CollegeMinistry from './ministry/CollegeMinistry'
import ErrorPage from './error/ErrorPage'
import LeadershipInformation from './new-here/leadership/LeadershipInformation'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Karla', 'Avenir', 'Roboto', sans-serif;
    box-sizing: border-box;
  }

  div {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<ErrorPage />}>
      <Route index element={<Home />} />
      <Route path="new" element={<NewHere />}>
        <Route path="welcome" element={<WelcomeSection />} />
        <Route path="gospel" element={<GospelSection />} />
        <Route path="time-and-location" element={<TimesAndLocationSection />} />
        <Route path="rides" element={<RidesSection />} />
        <Route path="faqs" element={<FaqsSection />} />
        <Route path="leadership" element={<LeadershipSection />} />
        <Route path="leadership/:id" element={<LeadershipInformation />} />
        <Route path="constitution" element={<ConstitutionSection />} />
        <Route
          path="doctrinal-statement"
          element={<DoctrinalStatementSection />}
        />
        <Route
          path="membership-covenant"
          element={<MembershipCovenantSection />}
        />
      </Route>
      <Route path="ministry" element={<Ministry />}>
        <Route path="partner" element={<MinistryPartners />} />
        <Route path="men" element={<MensMinistry />} />
        <Route path="women" element={<WomensMinistry />} />
        <Route path="college" element={<CollegeMinistry />} />
        <Route path="children" element={<ChildrensMinistry />} />
        <Route path="community-groups" element={<CommunityGroups />} />
        <Route path="adult-small-groups" element={<AdultSmallGroups />} />
      </Route>
      <Route path="class" element={<Lesson />}>
        <Route path="church-membership" element={<ChurchMembership />} />
        <Route path="equipping-classes" element={<EquippingClasses />} />
      </Route>
      <Route path="sermon" element={<SermonView />} />
      <Route path="giving" element={<GivingView />} />
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <ThemeProvider theme={customTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
)
