import { FC } from 'react'
import { styled } from 'styled-components'
import { Sermon } from '../client/types/sermon'
import SermonItem from './SermonItem'

type RecentSermonListProps = {
  recentSermons: Sermon[]
}

const RecentSermonListContainer = styled.div`
  width: 100%;
`

const RecentSermonList: FC<RecentSermonListProps> = ({ recentSermons }) => {
  return (
    <RecentSermonListContainer>
      {recentSermons.map((sermon: Sermon) => (
        <SermonItem
          key={sermon.title + sermon.date}
          sermon={sermon}
          sermonSeriesTitle={sermon.tagId}
        />
      ))}
    </RecentSermonListContainer>
  )
}

export default RecentSermonList
