import { Stack, Typography, useMediaQuery } from '@mui/material'
import { FC, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import styled from 'styled-components'
import InfoBox from '../common/InfoBox'
import InfoCard from '../common/InfoCard'
import { PlanningMailToLink } from '../common/MailToLink'
import Paragraph from '../common/Paragraph'
import ResponsiveContainer from '../common/ResponsiveContainer'
import {
  CCF_ABOUT_TITLE,
  CCF_SMALL_GROUPS_TITLE,
  CCF_TIME_AND_LOCATION_DETAIL,
  CCF_TIME_AND_LOCATION_TITLE,
  CCF_TOPICS_LIST,
  CCF_TOPICS_TITLE,
  CCF_ABOUT_DESCRIPTION,
  COLLEGE_MEN_SMALL_GROUP_TITLE,
  COLLEGE_WOMEN_SMALL_GROUP_TITLE,
  CCF_SMALL_GROUPS_DESCRIPTION,
} from './strings'
import customTheme from '../theme'

const InfoContainer = styled(Stack)`
  padding: 0 0 30px 0;
`

const CalendarContainer = styled.div`
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    .fc-toolbar.fc-header-toolbar {
      font-size: 60%;
    }
  }
`
export type CollegeSmallGroupInfo = {
  year: string
  leaderNames: string
}

const COLLEGE_WOMEN_SMALL_GROUP_LEADERS: CollegeSmallGroupInfo[] = [
  {
    year: 'Freshmen',
    leaderNames: 'Hailey Ung & Shannon Wu',
  },
  {
    year: 'Sophomores',
    leaderNames: 'Janet Elassal & Julie Leung',
  },
  {
    year: 'Juniors',
    leaderNames: 'Elena Honghin & Faith Ogawa',
  },
  {
    year: 'Seniors',
    leaderNames: 'Kathy Tran & Lynn Gao',
  },
]

const COLLEGE_MEN_SMALL_GROUP_LEADERS: CollegeSmallGroupInfo[] = [
  {
    year: 'Freshmen',
    leaderNames: 'Ethan Martinez & Sam Tan',
  },
  {
    year: 'Sophomores',
    leaderNames: 'Adan Jeronimo',
  },
  {
    year: 'Juniors',
    leaderNames: 'Sylvester Yue & Ivan Huang',
  },
  {
    year: 'Seniors',
    leaderNames: 'Alex Nguyen',
  },
]

type SmallGroupInfoSectionProps = {
  title: string
  listOfSmallGroupInfo: CollegeSmallGroupInfo[]
}

const SmallGroupInfoSection: FC<SmallGroupInfoSectionProps> = ({
  title,
  listOfSmallGroupInfo,
}) => {
  return (
    <InfoContainer direction="column">
      <Typography variant="h3" sx={{ margin: '0 0 30px' }}>
        {title}
      </Typography>
      {listOfSmallGroupInfo.map((info) => (
        <>
          <Typography variant="h4" sx={{ marginBottom: '5px' }}>
            {info.year}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '20px' }}>
            {info.leaderNames}
          </Typography>
        </>
      ))}
    </InfoContainer>
  )
}

const StyledInfoBox = styled(InfoBox)`
  > div:first-child {
    margin: 0 0 30px;
  }

  > div:not(:last-child) {
    border-bottom: 1px #d3d3d3 solid;
  }
`

const welcomeWeekId = '#welcome-week-about'

const CollegeMinistry: FC = () => {
  const matches = useMediaQuery(customTheme.breakpoints.down('sm'))
  const location = useLocation()
  const welcomeWeekRef = useRef<HTMLDivElement>(null)
  const calendarRef = useRef<FullCalendar>(null)

  useEffect(() => {
    // Timeout is necessary for the DOM to load with the ref.
    setTimeout(() => {
      if (location.hash === welcomeWeekId) {
        welcomeWeekRef.current?.scrollIntoView()
        window.scrollTo(0, window.scrollY - 100)
      } else {
        window.scrollTo(0, 0)
      }
    }, 25)
  }, [location])

  const onWindowResizeCalendar = () => {
    const calendarApi = calendarRef.current?.getApi()
    if (calendarApi) {
      if (matches) {
        calendarApi.changeView('listMonth')
      } else {
        calendarApi.changeView('dayGridMonth')
      }
    }
  }
  return (
    <ResponsiveContainer>
      <InfoCard>
        <Typography variant="body2">{CCF_TIME_AND_LOCATION_TITLE}</Typography>
        <Typography gutterBottom>{CCF_TIME_AND_LOCATION_DETAIL}</Typography>
        <iframe
          title="google-maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.4885520626667!2d-117.84532602387608!3d33.64450087331325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcde0fd7b961b3%3A0x2d09ff0c9e378e16!2sParkview%20Classroom%20Building!5e0!3m2!1sen!2sus!4v1742707638989!5m2!1sen!2sus"
          style={{ border: 0, width: '100%', height: '400px' }}
          aria-hidden="false"
          allowFullScreen
        />
      </InfoCard>
      <InfoCard>
        <Typography variant="body2">{CCF_TOPICS_TITLE}</Typography>
        <ul>
          {CCF_TOPICS_LIST.map((topic) => (
            <li>{topic}</li>
          ))}
        </ul>
      </InfoCard>
      <CalendarContainer>
        <FullCalendar
          plugins={[dayGridPlugin, listPlugin, googleCalendarPlugin]}
          initialView={window.innerWidth > 600 ? 'dayGridMonth' : 'listMonth'}
          windowResize={onWindowResizeCalendar}
          ref={calendarRef}
          googleCalendarApiKey="AIzaSyAZ-XJJRcGOhjuEplo4ELuwauGskW_uaN8"
          events={{
            googleCalendarId: 'ccf.crosslifecollegefellowship@gmail.com',
          }}
        />
      </CalendarContainer>
      <InfoCard>
        <Typography variant="body2">{CCF_ABOUT_TITLE}</Typography>
        <Typography>{CCF_ABOUT_DESCRIPTION}</Typography>
      </InfoCard>
      <InfoCard>
        <Typography variant="body2">{CCF_SMALL_GROUPS_TITLE}</Typography>
        <Paragraph>
          {CCF_SMALL_GROUPS_DESCRIPTION}
          <PlanningMailToLink />.
        </Paragraph>
        <StyledInfoBox variant="primary">
          <SmallGroupInfoSection
            title={COLLEGE_WOMEN_SMALL_GROUP_TITLE}
            listOfSmallGroupInfo={COLLEGE_WOMEN_SMALL_GROUP_LEADERS}
          />
          <SmallGroupInfoSection
            title={COLLEGE_MEN_SMALL_GROUP_TITLE}
            listOfSmallGroupInfo={COLLEGE_MEN_SMALL_GROUP_LEADERS}
          />
        </StyledInfoBox>
      </InfoCard>
    </ResponsiveContainer>
  )
}

export default CollegeMinistry
